<template>
  <component
    :is="hasLink ? sbLink : 'div'"
    v-editable="blok"
    :link="blok.link"
    class="group flex flex-row border-y text-xl transition-colors"
    :class="[
      blok.bg_light
        ? 'bg-blue-dark border-blue text-white dark:bg-white dark:border-green-lighter dark:text-blue-dark'
        : 'bg-white border-green-lighter text-blue-dark dark:bg-blue-dark dark:border-blue dark:text-white',
      hasLink ? (blok.bg_light ? 'active:bg-blue dark:active:bg-blue-lighter' : 'active:bg-blue-lighter dark:active:bg-blue') : '',
      blok.small ? 'py-2' : 'py-4',
    ]"
  >
    <div class="container-ext container mx-auto flex w-full items-center justify-center gap-4 px-4">
      <div
        class="inline-images !max-w-[unset]"
        :class="[blok.bg_light ? 'prose-invert' : 'dark:prose-invert', blok.small ? 'prose-lg' : 'prose-xl tablet:prose-lg desktop:prose-xl']"
        v-html="text"
      />
      <nuxt-icon
        v-if="hasLink"
        name="chevron-right"
        filled
        class="transition-transform duration-300 group-hover:translate-x-2 group-focus:translate-x-2 [&>svg]:!mb-0"
        :class="blok.small ? 'text-lg' : 'text-xl'"
      />
    </div>
  </component>
</template>

<script setup lang="ts">
import type { RibbonStoryblok } from "~/storyblok-component-types"
import sbLink from "~/components/sb-link.vue"

const props = defineProps<{ blok: RibbonStoryblok; }>()
const text = renderRTF(props.blok?.text)

const hasLink = computed(() => renderSbLink(props.blok.link) !== "/")
</script>
